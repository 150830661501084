import React from 'react';
import _ from 'underscore';
import OffersFilterBox from './offers_filter_box';
import OffersList from './offers_list';

export default class OffersBox extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      offers: [],
      activeOfferType: null,
      activeOfferSubtypesIds: null
    };

    this.setTypeFilter = this.setTypeFilter.bind(this);
    this.setSubtypeFilter = this.setSubtypeFilter.bind(this);
  }

  componentDidMount() {
    this.loadOffersFromServer();
  }

  setTypeFilter(offerType) {
    this.setState({
      activeOfferType:        offerType,
      activeOfferSubtypesIds: _.pluck(offerType.offers_subtypes, 'id')
    });
  }

  setSubtypeFilter(subTypeId) {
    const ids   = this.state.activeOfferSubtypesIds;
    const index = ids.indexOf(subTypeId);

    if (index >= 0) {
      ids.splice(index, 1);
    } else {
      ids.push(subTypeId);
    }
    this.setState({ activeOfferSubtypesIds: ids });
  }

  loadOffersFromServer() {
    $.ajax({
      url: this.props.offersUrl,
      dataType: 'json',
      cache: false,
      success: (data) => {
        this.setState({ offers: data });
      },
      error: (xhr, status, err) => {
        console.error(this.props.offersUrl, status, err.toString());
      }
    });
  }

  render() {
    const offersCountByType = _.countBy(this.state.offers, 'offers_type_id');

    return (
      <div id='offersBox'>
        <OffersFilterBox
          onTypeClick={this.setTypeFilter}
          onSubtypeClick={this.setSubtypeFilter}
          activeOfferType={this.state.activeOfferType}
          offersCountByType={offersCountByType}
        />
        <br />
        <OffersList
          activeOfferType={this.state.activeOfferType}
          activeOfferSubtypesIds={this.state.activeOfferSubtypesIds}
          offersUrl={this.props.offersUrl}
          offers={this.state.offers}
        />
      </div>
    );
  }
}
