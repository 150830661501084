import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdultsSelect from './adults_select';
import ChildsSelect from './childs_select';

export default class PeopleSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adultsCount: this.props.adultsCount,
      childs: this.props.childs,
      isMaxAdults: false,
      ageGroupId: this.props.ageGroupId
    };

    this._handleChildsChange = this._handleChildsChange.bind(this);
    this._handleAdultsChange = this._handleAdultsChange.bind(this);
  }

  _handleChildsChange(age, factor) {
    const { onChange } = this.props;
    const newChilds = this.state.childs;

    newChilds[age] = newChilds[age] || 0;
    newChilds[age] += factor;
    this.setState({ childs: newChilds });
    // hook for parent component
    onChange(this.state.adultsCount, newChilds, this.state.ageGroupId);
  }

  _handleAdultsChange(factor) {
    const { onChange } = this.props;
    let newAdultsCount = this.state.adultsCount + factor;

    if (newAdultsCount < this.props.minAdultsCount) {
      newAdultsCount = this.props.minAdultsCount;
    } else if (newAdultsCount > this.props.maxAdultsCount) {
      newAdultsCount = this.props.maxAdultsCount;
    }

    this.setState({ adultsCount: newAdultsCount });
    // hook for parent component
    onChange(newAdultsCount, this.state.childs, this.state.ageGroupId);
  }

  render() {
    const {
      maxAdultsCount,
      minAdultsCount,
      maxChildsAge,
      maxChildsCount,
      childsCount
    } = this.props;

    return (
      <div className='people-select'>
        <AdultsSelect
          handleAdultsChange={this._handleAdultsChange}
          adultsCount={this.state.adultsCount}
          maxAdultsCount={maxAdultsCount}
          minAdultsCount={minAdultsCount}
          maxChildsCount={maxChildsCount}

        />
        <ChildsSelect
          handleChildsChange={this._handleChildsChange}
          childs={this.state.childs}
          maxChildsAge={maxChildsAge}
          maxChildsCount={maxChildsCount}
          childsCount={childsCount}
        />
      </div>
    );
  }
}

PeopleSelect.propTypes = {
  maxAdultsCount: PropTypes.number.isRequired,
  minAdultsCount: PropTypes.number.isRequired,
  maxChildsAge: PropTypes.number.isRequired,
  adultsCount: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  childs: PropTypes.array,
  ageGroupId: PropTypes.number,
  maxChildsCount: PropTypes.number
};

PeopleSelect.defaultProps = {
  minAdultsCount: 0,
  maxAdultsCount: 20,
  adultsCount: 0,
  maxChildsAge: 17,
  childs: [],
  maxChildsCount: 2
};
