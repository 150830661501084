export default function ClientMemberDataCopy() {
  const fieldsToCopy = ['surname', 'name', 'patronymic', 'email', 'birthday', 'passport_number', 'phone'];
  const client = document.querySelector('.js-order__client-fields');
  const firstMember = document.querySelector('.js-order__members-fields');
  const memberNameData = firstMember.querySelector('.js-order__member-name-data');
  let memberData = '';

  fieldsToCopy.forEach((field) => {
    const clientField = client.querySelector(`[name$="[${field}]"]`);
    const memberField = field === 'email' ? firstMember.querySelector('[name*="[e_mail]"]') : firstMember.querySelector(`[name$="[${field}]"]`);

    if (field === 'surname' && clientField.value && memberNameData) memberData = clientField.value;
    if (field === 'name' && clientField.value && memberNameData) memberData = memberData.concat(` ${clientField.value[0]}.`);
    if (field === 'patronymic' && clientField.value && memberNameData) memberData = memberData.concat(` ${clientField.value[0]}.`);

    memberField.value = clientField.value;
  });
  if (memberNameData) memberNameData.innerHTML = memberData;
}
