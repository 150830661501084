import React from 'react';
import ReactDOM from 'react-dom';

export default class GoToOfferBookBlockButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: true
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    let _scrollTo = $(this.props.offerBookBlockId).offset().top - $(this.buttonDOM()).height() - 60;

    $('body, html').animate({
      scrollTop: _scrollTo
    }, 700);
    // this.setState({show: false});
  }

  buttonDOM() {
    return ReactDOM.findDOMNode(this.refs.button);
  }

  render() {
    const cssClasses = this.state.show ? 'w-event__nav-btn btn btn_color_yellow fullwidth js-offer-book-block-button__button-prices' : 'hide';

    return (
      <a className={cssClasses} ref='button' onClick={this.handleClick}>
        Цены
        <i className='fa fa-angle-double-down fa-fw fa-lg'></i>
      </a>
    );
  }

}
