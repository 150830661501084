export default function Accommodate() {
  const accommodateWithInput = document.querySelector('.js-order__accommodate-with-wish');
  const accommodateWithSelect = document.querySelector('.js-order__accommodate-with-wish-type');

  if (!accommodateWithSelect) return;

  if (accommodateWithSelect.value) {
    accommodateWithInput.style.display = 'block';
  }

  accommodateWithSelect.addEventListener('change', (e) => {
    if (e.target.value) {
      accommodateWithInput.style.display = 'block';
    } else {
      accommodateWithInput.style.display = 'none';
      accommodateWithInput.value = '';
    }
  });
}
