export default function Learned() {
  const learnedFromInput = document.querySelector('.js-order__learned-from');
  const learnedFromSelect = document.querySelector('.js-order__learned-from-type');

  if (!learnedFromSelect) return;

  if (['tinkoff', 'tele2', 'friends', 'site', 'social_media'].includes(learnedFromSelect.value)) {
    learnedFromInput.style.display = 'block';
  }

  learnedFromSelect.addEventListener('change', (e) => {
    const { value } = e.target;

    if (value === 'friends') {
      learnedFromInput.style.display = 'block';
      learnedFromInput.setAttribute('placeholder', 'ФИО или компания');
    } else if (value === 'social_site') {
      learnedFromInput.style.display = 'block';
      learnedFromInput.setAttribute('placeholder', 'Где именно?');
    } else if (value === 'tinkoff') {
      learnedFromInput.style.display = 'block';
      learnedFromInput.setAttribute('placeholder', 'Укажите промокод');
    } else if (value === 'tele2') {
      learnedFromInput.style.display = 'block';
      learnedFromInput.setAttribute('placeholder', 'Укажите промокод');
    } else {
      learnedFromInput.style.display = 'none';
      learnedFromInput.setAttribute('value', '');
    }
  });
}
