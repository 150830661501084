import React from 'react';

export default class ApartmentProgram extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      activeHotelsIds: this.props.activeHotelsIds
    };

    this._handleChange = this._handleChange.bind(this);
  }

  _handleChange(e) {
    let activeHotelsIds = this.state.activeHotelsIds,
        id = parseInt(e.target.value);

    if (e.target.checked) {
      activeHotelsIds.push(id);
    } else if(activeHotelsIds.length > 1) {
      activeHotelsIds.splice(activeHotelsIds.indexOf(id), 1);
    }
    this.setState({ activeHotelsIds: activeHotelsIds });
    // call parent callback
    this.props.onChange(activeHotelsIds);
  }


  render() {
    let hotelsNodes = [],
        hotel = null;

    for (let i = 0, l = this.props.program.hotels.length; i < l; i++) {
      hotel = this.props.program.hotels[i];
      if (hotel) {
        hotelsNodes.push(
          <div key={hotel.id}>
            <input
              type='checkbox'
              id={`hotel_${hotel.id}`}
              value={hotel.id}
              name={`hotel_${hotel.id}`}
              checked={this.state.activeHotelsIds.indexOf(hotel.id) >= 0 ? true : false }
              onChange={this._handleChange}
              className='fa-font-checkbox'
            />
            <label htmlFor={`hotel_${hotel.id}`} >{hotel.name}</label>
              {
                hotel.withDescription &&
                  <a href={hotel.location} target='_blank' className='offer-book-block__product-info-link'>
                <i className='fa fa-info-circle fa-lg'></i>
              </a>
              }
          </div>
        );
      }
    }

    return (
      <div className='offer-book-block__apartment-program'>
        <h6 className='font-gray'>{this.props.program.name}</h6>
        {hotelsNodes}
      </div>
    );
  }
}
