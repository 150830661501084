import ChangeAmountOnlyNumber from './Validates/ChangeAmountOnlyNumber';
import IsAmountValid from './Validates/IsAmountValid';
import {
  InternetAcquiring,
  ShowInternetAcquiringFields,
  HideInternetAcquiringFields,
  IsInternetAcquiringEqualZero
} from './InternetAcquiring';

export default function ChangeInputAmount(e) {
  e.preventDefault();

  const btn = document.querySelector('.js-sberbank-submit-button');
  const input = document.querySelector('.js-sberbank-amount');

  btn.disabled = true;

  ChangeAmountOnlyNumber(input);

  if (!IsAmountValid(input)) {
    btn.disabled = true;
    HideInternetAcquiringFields();
  } else {
    btn.disabled = false;
    InternetAcquiring();

    if (IsInternetAcquiringEqualZero()) {
      HideInternetAcquiringFields();
    } else {
      ShowInternetAcquiringFields();
    }
  }
}
