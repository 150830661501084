import React from 'react';
import _ from 'underscore';
import OfferItem from './offer_item';

export default class OffersList extends React.Component {

  render() {
    let offersUrl = this.props.offersUrl,
        activeOfferType = this.props.activeOfferType,
        activeOfferSubtypesIds = this.props.activeOfferSubtypesIds;

    let even = true;
    let offersNodes = this.props.offers.map(function (offer){

      let offerSubtypesIds = _.pluck(offer.offers_subtypes, 'id');

      /* Filter offers by types, subtypes  */
      if (activeOfferType.id === null || (activeOfferType.id === offer.offers_type_id) &&
                                        _.intersection(offerSubtypesIds, activeOfferSubtypesIds).length > 0) {
        return (
          <OfferItem  key={offer.id}
                      url={[offersUrl, offer.id].join('/')}
                      offer={offer}
                      even={even=!even}/> );
      }

    });

    return ( <div id="offersList">{offersNodes}</div> );
  }

}
