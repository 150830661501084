/* React UJS (call after Turbolinks) */
import ReactRailsUJS from 'react_ujs';
import moment from 'moment';
import Sticky from 'sticky-js';

import {
  initOffsetContact,
  initOffsetEvent,
  initMassMedia,
  initOfferType,
  initCallbackForm,
  initShowFormCallbackPhone,
  initPartnerForm
} from './javascript/main_page';

import { InitBtnLogout } from '../common/javascript/api';

import InputPhone from '../common/javascript/components/input_phone/input_phone';
import OffersBox from './javascript/components/offers_box/offers_box';
import GoToOfferBookBlockButton from './javascript/components/go_to_offer_book_block_button/go_to_offer_book_block_button';
import OfferBookBlock from './javascript/components/offer_book_block/offer_book_block';
// import SubOrderPeopleSelect from './javascript/components/sub_orders_people_select/sub_orders_people_select';
// import { initJivoSite } from './javascript/jivo_site/initJivoSite';
import { InitBtnSubmit, InitInputAmount } from './javascript/PaymentSberbank/PaymentSberbank';
import { ReachGoal } from './javascript/YandexMetrika';
import { Ga } from './javascript/GoogleAnalytics';
import { Goal } from './javascript/Vk';
import { initDataCopy } from '../common/javascript/Order/ClientMemberDataCopy';
import { CashBackMir } from './javascript/CashBackMir';
import {
  SpotwayLogoDefaultGradient,
  RecallGradient,
  InstagramGradient,
  MirGradient,
  VisaGradient
} from '../common/javascript/components/LinerGradient';
import AuthSiteHeader from './javascript/components/SiteHeader/AuthSiteHeader';
import SiteFooter from './javascript/components/SiteFooter';
import Slider from './javascript/components/Slider';
import OrderSection from './javascript/components/OrdersPage/OrderSection';
import BonusesList from './javascript/components/BonusesList/BonusesList';

window.OffersBox = OffersBox;
window.GoToOfferBookBlockButton = GoToOfferBookBlockButton;
window.OfferBookBlock = OfferBookBlock;
// window.SubOrderPeopleSelect = SubOrderPeopleSelect;
window.InputPhone = InputPhone;
window.SpotwayLogoDefaultGradient = SpotwayLogoDefaultGradient;
window.RecallGradient = RecallGradient;
window.InstagramGradient = InstagramGradient;
window.MirGradient = MirGradient;
window.VisaGradient = VisaGradient;
window.AuthSiteHeader = AuthSiteHeader;
window.SiteFooter = SiteFooter;
window.Slider = Slider;
window.OrderSection = OrderSection;
window.BonusesList = BonusesList;

ReactRailsUJS.detectEvents();
ReactRailsUJS.mountComponents();

function initScrollHeader(e) {
  const scroll = e.currentTarget.scrollY;
  const infoPayment = document.querySelector('.js-order__cost-info-wrap');

  if (scroll >= 100) {
    if (!infoPayment) return;
    if (infoPayment.sticky) infoPayment.sticky.marginTop = 100;
  } else {
    if (!infoPayment) return;
    if (infoPayment.sticky) infoPayment.sticky.marginTop = 0;
  }
}

function clickReachGoal(e) {
  if (e.currentTarget.dataset.reachgoal) {
    ReachGoal(e.currentTarget.dataset.target, { offer: e.currentTarget.dataset.offer });
  }
}

function clickGa(e) {
  if (e.currentTarget.dataset.ga) {
    Ga(e.currentTarget.dataset.target);
  }
}

function clickGoal(e) {
  if (e.currentTarget.dataset.vkgoal) {
    Goal(e.currentTarget.dataset.target);
  }
}

function initReachGoal() {
  const booking = document.querySelector('.js-events__go-to-booking');

  if (booking) {
    booking.removeEventListener('click', clickReachGoal, false);
    booking.addEventListener('click', clickReachGoal, false);
  }
}

function initGa() {
  const booking = document.querySelector('.js-events__go-to-booking');

  if (booking) {
    booking.removeEventListener('click', clickGa, false);
    booking.addEventListener('click', clickGa, false);
  }
}

function initGoal() {
  const booking = document.querySelector('.js-events__go-to-booking');

  if (booking) {
    booking.removeEventListener('click', clickGoal, false);
    booking.addEventListener('click', clickGoal, false);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  initOffsetContact();
  initOffsetEvent();
  initMassMedia();
  initOfferType();
  initCallbackForm();
  initShowFormCallbackPhone();
  initPartnerForm();
  InitBtnSubmit();
  InitInputAmount();
  initReachGoal();
  initGa();
  initGoal();
  initDataCopy();
  CashBackMir();
  InitBtnLogout();

  /* Set locale for Moment.js */
  moment.locale('ru');

  const sticky = new Sticky('.js-order__cost-info-wrap');
  sticky.update();

  // initJivoSite();
});

window.addEventListener('scroll', (e) => {
  initScrollHeader(e);
});
