import ChangeInputAmount from './ChangeInputAmount';

export default function InitInputAmount() {
  const input = document.querySelector('.js-sberbank-amount');

  if (input) {
    input.removeEventListener('input', ChangeInputAmount, false);
    input.addEventListener('input', ChangeInputAmount, false);
  }
}
