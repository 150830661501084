import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DayPicker,  { DateUtils } from 'react-day-picker';
import LocaleUtils from 'react-day-picker/moment';


export default class OfferBookDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };

    this.handleDayClick = this.handleDayClick.bind(this);
  }

  handleDayClick(day, modifiers, e) {
    if (modifiers.disabled) {
      return;
    }

    const {
      onChange
    } = this.props;

    onChange(day);
  }

  render() {
    const {
      firstDate,
      lastDate,
      startDate,
      finishDate,
      choosingStartDate
    } = this.props;
    const currentFirstDate = moment(firstDate).toDate();
    const currentLastDate = moment(lastDate).toDate();
    const currentStartDate = startDate;
    const currentFinishDate = finishDate;
    const initialMonth = currentFinishDate
      || currentStartDate
      || currentFirstDate
      || moment().toDate();
    const currentChoosingStartDate = choosingStartDate;

    const modifiers = {
      'weekend': (day) => {
        return moment(day).isoWeekday() === 7
          || moment(day).isoWeekday() === 6;
      },

      'start-candidate': (day) => {
        return currentChoosingStartDate
          && !moment(day).isSame(currentFinishDate)
          || moment(day).isBefore(currentStartDate);
      },

      'finish-candidate': (day) => {
        return !currentChoosingStartDate
          && !moment(day).isSame(currentStartDate)
          && moment(day).isAfter(currentStartDate);
      },

      'start': (day) => {
        return currentStartDate
          && moment(day).isSame(currentStartDate);
      },

      'finish': (day) => {
        return currentFinishDate
          && moment(day).isSame(currentFinishDate);
      },


      'range': (day) => {
        return currentFinishDate
          && (moment(day).isBetween(currentStartDate, currentFinishDate)
          || moment(day).isBetween(currentFinishDate, currentStartDate));
      },

      'disabled': (day) => {
        return moment(day).isBefore(moment())
          || moment(day).isBefore(currentFirstDate, 'd')
          || moment(day).isAfter(currentLastDate, 'd');
      }
    };

    return (
      <DayPicker
        locale='ru'
        localeUtils={LocaleUtils}
        onDayClick={this.handleDayClick}
        modifiers={modifiers}
        fromMonth={currentFirstDate}
        toMonth={currentLastDate}
        initialMonth={initialMonth}
        showOutsideDays
      />
    );
  }
}

OfferBookDatePicker.propTypes = {
  firstDate: PropTypes.string.isRequired,
  lastDate: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date),
  finishDate: PropTypes.instanceOf(Date),
  choosingStartDate: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};
