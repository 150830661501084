import React from 'react';

export default class OfferType extends React.Component {
  render() {
    let classNames = this.props.active ? 'active' : '';

    return (
      <li className={classNames} onClick={this.props.onTypeClick.bind(null, this.props.offerType)}>
        <span>{this.props.offerType.name}
          <span className="count">{this.props.count}</span>
        </span>
      </li>
    );
  }
}
