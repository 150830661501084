function HideCashBackBlock() {
  const block = document.querySelector('.js-cashback-mir__block');

  if (!block) return;

  block.style.display = 'none';
}

function HideBtnRegistration() {
  const btn = document.querySelector('.js-cashback-mir__submit-button');

  if (!btn) return;

  btn.style.display = 'none';
}

function HideBtnReturn() {
  const btn = document.querySelector('.js-cashback-mir__return-button');

  if (!btn) return;

  btn.style.display = 'none';
}

function HidePaymentForm() {
  const form = document.querySelector('.js-sberbank__form-block');

  if (!form) return;

  form.style.display = 'none';
}

function ShowPaymentForm() {
  const form = document.querySelector('.js-sberbank__form-block');
  const fullCost = document.querySelector('.js-sberbank-full-cost');
  const fullAmount = document.querySelector('.js-sberbank-full-amount');
  const btn = document.querySelector('.js-sberbank-submit-button');
  const input = document.querySelector('.js-sberbank-amount');
  const stateCashBack = localStorage.getItem('cashback');

  if (!form) return;

  form.style.display = 'block';

  if (stateCashBack === '0') {
    input.disabled = false;
    input.value = 0;
    fullAmount.value = 0;
    btn.disabled = true;
  }

  if (stateCashBack === '1') {
    input.disabled = true;
    input.value = fullCost.value;
    fullAmount.value = fullCost.value;
    btn.disabled = false;
  }
}

function ChangeCashbackState(state) {
  const form = document.querySelector('.js-sberbank-form');

  if (!form) return;

  form.cashback.value = state;
  localStorage.setItem('cashback', state);
}

function CreateIframeRegistration(marker) {
  const ifrm = document.createElement('iframe');
  ifrm.style.width = '100%';
  ifrm.style.height = '500px';
  ifrm.style.border = 0;
  marker.parentNode.insertBefore(ifrm, marker);
  ifrm.setAttribute('id', 'js-cashback-mir__iframe-id');
  ifrm.setAttribute('src', 'https://privetmir.ru/rostourism/register/allpartners/');
}

function RemoveIframeRegistration() {
  const ifrm = document.getElementById('js-cashback-mir__iframe-id');

  if (ifrm) ifrm.remove();
}

function addEventer() {
  const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
  const eventer = window[eventMethod];
  const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

  eventer(messageEvent, (e) => {
    if (e.data === 'iframe_process_complete') {
      console.log('Complete iframe registration');
      ChangeCashbackState(1);
      RemoveIframeRegistration();
      HideCashBackBlock();
      ShowPaymentForm();
      HideBtnReturn();
      return;
    }
    try {
      const data = JSON.parse(e.data);

      console.log(data);

      if (!data.isSuccess) return;

      if (data.isSuccess) {
        console.log('Complete iframe registration isSuccess NEW');
        ChangeCashbackState(1);
        RemoveIframeRegistration();
        HideCashBackBlock();
        ShowPaymentForm();
        HideBtnReturn();
      }
      // window.location.href = data.redirectUrl;

      /*
      data = {
        redirectUrl: 'http://'
      };
      */
    } catch (error) {
      // failed
    }
  });
}

function ClickBtnRegistrationSubmit() {
  console.log('ClickBtnRegistrationSubmit');
  const marker = document.querySelector('.js-cashback-mir__iframe-marker');

  if (!marker) return;

  addEventer();
  CreateIframeRegistration(marker);
  HidePaymentForm();
  HideBtnRegistration();
  // ShowBtnReturn();
}

// function ClickBtnReturn() {
//   console.log('ClickBtnReturn');
//   ChangeCashbackState(0);
//   RemoveIframeRegistration();
//   ShowPaymentForm();
//   HideBtnReturn();
//   ShowBtnRegistration();
// }

function ClickBtnWithoutCashBack() {
  console.log('ClickBtnWithoutCashBack => ', 1);
  localStorage.setItem('cashback', 0);
  const mirBlock = document.querySelector('.js-cashback-mir__block');
  const frmBlock = document.querySelector('.js-sberbank__form-block');
  const imgMir = document.getElementById('mir');
  const imgVizaMasterMir = document.getElementById('visa-master-mir');
  const input = document.querySelector('.js-sberbank-amount');

  if (!mirBlock) return;

  frmBlock.style.display = 'block';
  mirBlock.style.display = 'none';

  imgMir.style.display = 'none';
  imgVizaMasterMir.style.display = 'block';
  input.disabled = false;
}

function ClickBtnWithCashBack() {
  console.log('ClickBtnWithCashBack => ', 1);
  const mirBlock = document.querySelector('.js-cashback-mir__block');
  const frmBlock = document.querySelector('.js-sberbank__form-block');
  const btnCachBask = document.querySelector('.js-cashback-mir__submit-button');
  const imgMir = document.getElementById('mir');
  const imgVizaMasterMir = document.getElementById('visa-master-mir');
  const stateCashBack = localStorage.getItem('cashback');

  if (!mirBlock) return;

  if (stateCashBack === '0') {
    frmBlock.style.display = 'none';
    mirBlock.style.display = 'block';
  }

  if (stateCashBack === '1') {
    frmBlock.style.display = 'block';
    mirBlock.style.display = 'none';
  }

  imgMir.style.display = 'block';
  imgVizaMasterMir.style.display = 'none';

  if (!btnCachBask) return;

  if (btnCachBask) {
    btnCachBask.removeEventListener('click', ClickBtnRegistrationSubmit, false);
    btnCachBask.addEventListener('click', ClickBtnRegistrationSubmit, false);
    btnCachBask.style.display = 'block';
  }
}

export default function CashBackMir() {
  console.log('CashBackMir');
  const btnWithoutCashBack = document.querySelector('.js-mir__btn-without-cashback');
  const btnWithCashBack = document.querySelector('.js-mir__btn-with-cashback');
  // const btnCachBaskReturn = document.querySelector('.js-cashback-mir__return-button');

  localStorage.setItem('cashback', 0);

  if (btnWithoutCashBack) {
    btnWithoutCashBack.removeEventListener('click', ClickBtnWithoutCashBack, false);
    btnWithoutCashBack.addEventListener('click', ClickBtnWithoutCashBack, false);
  }

  if (btnWithCashBack) {
    btnWithCashBack.removeEventListener('click', ClickBtnWithCashBack, false);
    btnWithCashBack.addEventListener('click', ClickBtnWithCashBack, false);
  }
  // if (btnCachBaskReturn) {
  //   btnCachBaskReturn.removeEventListener('click', ClickBtnReturn, false);
  //   btnCachBaskReturn.addEventListener('click', ClickBtnReturn, false);
  // }
}
