import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ChildsSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSelecting: false
    };

    this.handleToggleAgeSelect = this.handleToggleAgeSelect.bind(this);
    this._handleChildsChange = this._handleChildsChange.bind(this);
  }

  handleToggleAgeSelect() {
    if (this.props.maxChildsCount === this.props.childsCount) return;

    this.setState({ isSelecting: !this.state.isSelecting });
  }

  getAgeText(age) {
    if (age === 0 || age > 4) return 'лет';
    if (age === 1) return 'год';
    if (age >= 2 && age <= 4) return 'года';
  }

  showOrHide() {
    return this.props.maxChildsCount === 0 ? 'hide' : '';
  }

  _handleChildsChange(age, factor) {
    const { handleChildsChange } = this.props;

    this.setState({ isSelecting: false });
    handleChildsChange(age, factor);
  }

  render() {
    const { maxChildsAge, childs } = this.props;
    const childAges = [];
    const childItems = [];
    let childAgeSelect;

    for (let i = 0; i <= maxChildsAge; i++) {
      childAges.push(
        <div
          key={`age_${i}`}
          className='people-select__child-age-select-cell'
          onClick={this._handleChildsChange.bind(null, i, 1)}
        >
          <span className='people-select__child-age-select-cell-digit'>{i}</span>
          <span className='people-select__child-age-select-cell-text'>
            {this.getAgeText(i)}
          </span>
        </div>
      );

      if (childs[i] > 0) {
        for (let b = 0; b < childs[i]; b++) {
          childItems.push(
            <div
              key={`child[${i}]-${b}`}
              className='people-select__child-item'
            >
              <i
                className='people-select__remove-button'
                onClick={this._handleChildsChange.bind(null, i, -1)}
              />
              <i
                className='fa fa-child fa-2x'
              />
              <br/>
              <span
                className='people-select__child-age'
              >
                {`${i} ${this.getAgeText(i)}`}
              </span>
            </div>
          );
        }
      }
    }

    if (this.state.isSelecting) {
      childAgeSelect = (
        <div
          className='people-select__child-age-select'
        >
          Сколько полных лет на дату выезда?
          <br />
          {childAges}
          <br />
          <a
            href='javascript:void(0);'
            className='people-select__close-link'
            onClick={this.handleToggleAgeSelect}
          >
            Закрыть
          </a>
        </div>
      );
    }

    const maxChildsDisabledClass = this.props.maxChildsCount === this.props.childsCount
      ? ' people-select__add-button--disabled'
      : '';

    return (
      <div className={`people-select__childs ${this.showOrHide()}`}>
        <h6 className='font-gray'>Дети (0-17 лет)</h6>
        <div className='people-select__category-row'>
          <div className='people-select__pax-cell'>
            {childItems}
          </div>
        </div>
        <div className='people-select__category-row'>
          <div className='people-select__add-button-cell'>
            <i
              className={`people-select__add-button${maxChildsDisabledClass}`}
              onClick={this.handleToggleAgeSelect}
            />
            {childAgeSelect}
          </div>
        </div>
      </div>
    );
  }
}

ChildsSelect.propTypes = {
  maxChildsAge: PropTypes.number.isRequired,
  childs: PropTypes.array.isRequired,
  handleChildsChange: PropTypes.func.isRequired,
  maxChildsCount: PropTypes.number.isRequired
};
