import React from 'react';
import OfferTypesFilter from './offer_types_filter';
import OfferSubtypesFilter from './offer_subtypes_filter';

export default class OffersFilterBox extends React.Component {

  render() {
    let offerSubtypesFilter = this.props.activeOfferType ? <OfferSubtypesFilter subTypes={this.props.activeOfferType.offers_subtypes} onSubtypeClick={this.props.onSubtypeClick} /> : '';

    return (
      <div className='offers-filter-box hide'>
        <OfferTypesFilter
          onTypeClick     = {this.props.onTypeClick}
          activeOfferType = {this.props.activeOfferType}
          offersCountByType = {this.props.offersCountByType}
        />
        {offerSubtypesFilter}
      </div>
    );
  }
}
