import React from 'react';
import _ from 'underscore';
import OfferType from './offer_type';

export default class OfferTypesFilter extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      offerTypes:[]
    };
  }

  componentDidMount() {

    // nullType - show all offers
    let nullType = { id: null, name: 'Все', offers_subtypes: [] };

    // Imitate click on nullType for set as initial for OffersBox
    this.props.onTypeClick(nullType);

    $.ajax({
      url: '/offers_types',
      dataType: 'json',
      cache: false,
      success: (data) => {
        data.unshift(nullType);
        this.setState({offerTypes: data});
      },
      error: (xhr, status, err) => {
        console.error('/offers_types', status, err.toString());
      }
    });
  }

  getOffersCount(offersCountByType, offerType) {
    let count = offersCountByType[offerType.id];

    // if the count is undefined, set count equal 0
    count = count ? count : 0;
    // if the offerType id is null that means that it is ALL type and wee need count all offers
    if (offerType.id === null) {
      let ar = _.values(offersCountByType);

      for (let i = 0; i < ar.length; i++) { count += ar[i]; }
    }

    return count;
  }

  render() {
    let that = this;

    let active, count = null;
    // Всего активных предложений
    let countAllOffers = 0;
    let offerTypesNodes = this.state.offerTypes.map(function (offerType){

      count = that.getOffersCount( that.props.offersCountByType, offerType );
      active = that.props.activeOfferType ? that.props.activeOfferType.id === offerType.id : false;
      // Если одно активное предложение, фильтр не показывать
      if (offerType.id == null && count === 1) { countAllOffers = 1; return; }
      else if (offerType.id == null && count > 1) { countAllOffers = count; }
      // Если нет предложений в типе предложения, фильтр для этого типа не показывать
      if (count > 0 && countAllOffers > 1){
        return (<OfferType offerType={offerType} key={offerType.id} onTypeClick={that.props.onTypeClick} active={active} count={count}/>);
      }
    });

    return (
      <div className="offers-type-filter row">
        <ul className="inline-list">{offerTypesNodes}</ul>
      </div>
    );
  }
}
