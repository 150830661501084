import moment from 'moment';
import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import PeopleSelect from '../../../../common/javascript/components/people_select/people_select';
import SingleChoiceProgram from './single_choice_program';
import ApartmentProgram from './apartment_program';
import OfferBookDatePicker from './offer_book_date_picker';

export default class OfferBookBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      finishDate: null,
      choosingStartDate: true,
      adultsCount: 1,
      datesError: null,
      childs: [],
      showMaxAdultsMsg: false,
      showMaxChildsMsg: false,
      childsCount: 0,
      /**
        activeHotels = {
          program_id: [active_hotel_id, active_hotel_id, ..]
        }
      */
      activeHotels: this.initialActiveHotels(),

      /**
        activeSingleChoiceProducts = {
          program_id: active_product_id
        }
      */
      activeSingleChoiceProducts: this.initialActiveSingleChoiceProducts()
    };

    this.handleSingleChoiceProgramChange = this.handleSingleChoiceProgramChange.bind(this);
    this.handleApartmentProgramChange = this.handleApartmentProgramChange.bind(this);
    this.handleClearDates = this.handleClearDates.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handlePeopleSelectChange = this.handlePeopleSelectChange.bind(this);
    this.handleFindAction = this.handleFindAction.bind(this);

    this.offerBookBlock = null;

    this.setOfferBookBlockRef = (e) => {
      this.offerBookBlock = e;
    };
  }

  // componentDidMount() {
  //   $(document).foundation();
  // }

  // componentDidUpdate() {
  //   const offerBookBlock = this.offerBookBlockRef.current;
  //   console.log(this.offerBookBlock);
  //   this.offerBookBlock.foundation();
  // }

  handleClearDates() {
    this.setState({ startDate: null, finishDate: null });
  }

  /*
    Here what happens when user click search button.
    We build query params depending on component's state.
  */
  handleFindAction() {
    const {
      allowCustomDuration,
      dateFormat,
      loadingImgPath,
      searchUrl
    } = this.props;
    const {
      finishDate,
      startDate,
      childs,
      adultsCount,
      activeSingleChoiceProducts,
      activeHotels
    } = this.state;
    const paramsForSearch = {};

    if (allowCustomDuration && !finishDate) {
      this.setState({ showDatesError: true });
      return;
    }
    // Start date
    // if(this.state.startDate) {
    paramsForSearch.start_date = moment(startDate).format(dateFormat);
    // }

    // Finish date
    // if(this.state.finishDate) {
    paramsForSearch.finish_date = moment(finishDate).format(dateFormat);
    // }

    // People select
    const age_data = childs;

    age_data[99] = adultsCount;

    for (let key in age_data) {
      paramsForSearch[`age_data[${key}][name]`] = `age_${key}`;
      paramsForSearch[`age_data[${key}][value]`] = age_data[key];
    }

    const singleChoiceProducts = activeSingleChoiceProducts;
    const currentActiveHotels = activeHotels;
    let singleChoiceProductsIndex = 0;

    // Active Single Choice Products by program
    for (let programId in singleChoiceProducts) {
      paramsForSearch[`single_choice_products[${singleChoiceProductsIndex}][product_id]`] = singleChoiceProducts[programId];
      paramsForSearch[`single_choice_products[${singleChoiceProductsIndex}][program_id]`] = parseInt(programId, 10);
      singleChoiceProductsIndex++;
    }

    //  Active Hotels by program
    for (let programId in currentActiveHotels) {
      paramsForSearch[`single_choice_products[${singleChoiceProductsIndex}][program_id]`] = parseInt(programId, 10);
      for (let i = 0, l = activeHotels[programId].length; i < l; i++) {
        paramsForSearch[`single_choice_products[${singleChoiceProductsIndex}][product_id][${i}]`] = currentActiveHotels[programId][i];
        paramsForSearch[`single_choice_products[${singleChoiceProductsIndex}][product_type]`] = 'aprt';
      }
      singleChoiceProductsIndex++;
    }


    const queryString = _.map(paramsForSearch, (value, key) => {
      return `${key}=${value}`;
    }).join('&');

    /* From here we are using jquery NOT GOOD */
    $('#offer-default-calc-wrap').html(`<div style="padding:50px 50px;text-align:center;"><img src=${loadingImgPath}></img><div>`);
    $('html, body').animate({ scrollTop: $('#offer-default-calc-wrap').offset().top - 80 }, 500);
    $.get(searchUrl, queryString, (data) => {
      // $('#offer-default-calc-wrap').html(data).foundation();
      $('#offer-default-calc-wrap').html(data);


      $('.js-default-calc-results__result-row-more-button').click(() => {
        $(`*[data-apt-hide-selector=${$(this).data('selector')}]`).removeClass('hide');
        $(this).remove();
      });
    });
  }

  /**
   * Handle changes from people select block
  */
  handlePeopleSelectChange(paramAdultsCount, paramChilds) {
    const {
      maxAdultsCount,
      maxChildsCount,
    } = this.props;
    const currentChildsArray = paramChilds.slice(0, 18).filter((elem) => { return elem !== 0 && elem; });
    const currentChildsCount = currentChildsArray.length === 0 ? 0 : currentChildsArray.reduce((a, b) => { return a + b; });

    this.setState({ adultsCount: paramAdultsCount, childs: paramChilds, childsCount: currentChildsCount });

    if (paramAdultsCount === maxAdultsCount) {
      this.setState({ showMaxAdultsMsg: true });
    } else {
      this.setState({ showMaxAdultsMsg: false });
    }

    if (currentChildsCount === maxChildsCount && maxChildsCount !== 0) {
      this.setState({ showMaxChildsMsg: true });
    } else {
      this.setState({ showMaxChildsMsg: false });
    }
  }


  /**
   * Handle change dates link click
  */
  handleSetNewDates() {
    this.setState({ startDate: null, finishDate: null, choosingStartDate: true });
  }

  /**
   * Handle changes from start date calendar
  */
  handleStartDateChange(day) {
    const {
      choosingStartDate,
      startDate
    } = this.state;
    if (choosingStartDate === true || moment(day).isBefore(startDate)) {
      this.setState({ startDate: day, finishDate: null, choosingStartDate: false });
    } else {
      this.setState({ finishDate: day, choosingStartDate: true, showDatesError: false });
    }
  }

  /**
   * Generate initial state for Single Choice products
  */
  initialActiveSingleChoiceProducts() {
    const {
      singleChoicePrograms
    } = this.props;
    const initial = {};

    for (let i = 0, l = singleChoicePrograms.length; i < l; i++) {
      const program = JSON.parse(singleChoicePrograms[i]);

      initial[program.id] = program.products[0].id;
    }
    return initial;
  }

  /**
   * Handle single choice products change
  */
  handleSingleChoiceProgramChange(programId, activeProductId) {
    const {
      activeSingleChoiceProducts
    } = this.state;
    const newActiveSingleChoiceProducts = activeSingleChoiceProducts;

    newActiveSingleChoiceProducts[programId] = activeProductId;
    this.setState({ activeSingleChoiceProducts: newActiveSingleChoiceProducts });
  }

  /**
  * Generate initial state for hotels
  */
  initialActiveHotels() {
    const {
      apartmentPrograms
    } = this.props;
    const initial = {};

    for (let i = 0, l = apartmentPrograms.length; i < l; i++) {
      const program = JSON.parse(apartmentPrograms[i]);

      initial[program.id] = _.pluck(program.hotels, 'id');
    }
    return initial;
  }

  /**
  * Handle apartment program (hotels) change
  */
  handleApartmentProgramChange(programId, activeHotelsIds) {
    const {
      activeHotels
    } = this.state;
    const newActiveHotels = activeHotels;

    newActiveHotels[programId] = activeHotelsIds;
    this.setState({ activeHotels: newActiveHotels });
  }

  /**
   * RENDER
   */
  render() {
    const singleChoiceProgramsNodes = [];
    const {
      singleChoicePrograms,
      apartmentPrograms,
      allowCustomDuration,
      firstDate,
      lastDate,
      startDates,
      onMaxAdultsText,
      onMaxChildsText,
      maxAdultsCount,
      maxChildsCount,
      offerId
    } = this.props;
    const {
      activeSingleChoiceProducts,
      activeHotels,
      startDate,
      finishDate,
      choosingStartDate,
      showMaxAdultsMsg,
      showMaxChildsMsg,
      adultsCount,
      childsCount,
      showDatesError
    } = this.state;

    for (let i = 0, l = singleChoicePrograms.length; i < l; i++) {
      const program = JSON.parse(singleChoicePrograms[i]);

      singleChoiceProgramsNodes.push(
        <SingleChoiceProgram
          key={program.id}
          program={program}
          activeProduct={activeSingleChoiceProducts[program.id]}
          onChange={this.handleSingleChoiceProgramChange.bind(null, program.id)}
        />
      );
    }

    const apartmentProgramsNodes = [];

    for (let i = 0, l = apartmentPrograms.length; i < l; i++) {
      const program = JSON.parse(apartmentPrograms[i]);

      apartmentProgramsNodes.push(
        <ApartmentProgram
          key={program.id}
          program={program}
          activeHotelsIds={activeHotels[program.id]}
          onChange={this.handleApartmentProgramChange.bind(null, program.id)}
        />
      );
    }

    return (
      <div
        id='offer-book-block'
        className='offer-book-block'
        ref={this.setOfferBookBlockRef}
      >
        <div className='row offer-book-block__inner'>
          <div className='small-12 large-12  columns '>
            <div className='row'>
              <div className='large-4 medium-12 columns offer-book-block__options-wrap'>
                <h4 className='offer-book-block__controls-header'>выбрать опции</h4>
                {singleChoiceProgramsNodes}
                {apartmentProgramsNodes}
              </div>
              {allowCustomDuration
                ? (
                  <div
                    className='large-4 medium-6 columns offer-book-block__calendar-wrap'
                  >
                    <h4
                      className='offer-book-block__controls-header'
                    >
                      даты заезда и выезда
                    </h4>
                    <div
                      className='offer-book-block__dates'
                    >
                      {startDate
                        ? <span className='offer-book-block__date'>{`${moment(startDate).format('DD.MM.YYYY')} - `}</span>
                        : 'Выбери дату заезда'
                      }
                      {startDate && !finishDate
                        ? 'Выбери дату выезда'
                        : ''
                      }
                      {finishDate
                        ? <span className='offer-book-block__date'>{moment(finishDate).format('DD.MM.YYYY')}</span>
                        : ''
                      }
                      {finishDate
                        ? <a
                            href='javascript:void(0);'
                            onClick={this.handleClearDates}
                          >
                            <i className='fa fa-times-circle fa-lg' />
                          </a>
                        : ''
                      }
                    </div>
                    <OfferBookDatePicker
                      onChange={this.handleStartDateChange}
                      firstDate={firstDate}
                      lastDate={lastDate}
                      startDates={startDates}
                      finishDate={finishDate}
                      startDate={startDate}
                      choosingStartDate={choosingStartDate}
                    />
                  </div>
                )
                : null
              }
              <div
                className='large-4 medium-6 end columns offer-book-block__people-select-wrap'
              >
                <h4
                  className='offer-book-block__controls-header'
                >
                  количество человек
                </h4>
                {showMaxAdultsMsg
                  ? (
                    <div className='nowrap'>
                      <span className='label warning radius offer-book-block__msg'>
                        {onMaxAdultsText}
                      </span>
                    </div>
                  )
                  : null
                }
                {showMaxChildsMsg
                  ? (
                    <div className='nowrap'>
                      <span className='label warning radius offer-book-block__msg'>
                        {onMaxChildsText}
                      </span>
                    </div>
                  )
                  : null
                }
                <PeopleSelect
                  onChange={this.handlePeopleSelectChange}
                  adultsCount={adultsCount}
                  maxAdultsCount={maxAdultsCount}
                  minAdultsCount={1}
                  maxChildsCount={maxChildsCount}
                  childsCount={childsCount}
                />
                <br />
                {showDatesError &&
                    <span className='label alert radius offer-book-block__msg'>
                        Не задана дата заезда или выезда
                    </span>
                }
                <button
                  className='offer-book-block__show-prices-button js-offer-book-block__show-prices'
                  onClick={this.handleFindAction}
                  data-offer-id={offerId}
                >
                  Показать цены
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OfferBookBlock.defaultProps = {
  dateFormat: 'DD.MM.YYYY',
  onMaxAdultsText: 'Достигнуто макс. кол-во взрослых в одном номере. Если вас едет больше, то просто оформите несколько заказов!',
  onMaxChildsText: 'Достигнуто макс. кол-во детей в одном номере. Если вас едет больше, то просто оформите несколько заказов!'
};

OfferBookBlock.propTypes = {
  singleChoicePrograms: PropTypes.array.isRequired
};
