function InternetAcquiring() {
  const acquiringField = document.querySelector('.js-sberbank-interest_rate_of_internet_acquiring');
  const input = document.querySelector('.js-sberbank-acquiring');
  const fullAmount = document.querySelector('.js-sberbank-full-amount');

  const acquiring = parseFloat(acquiringField.value);
  const amount = document.querySelector('.js-sberbank-amount');
  const pay = Math.floor(parseFloat(amount.value) / 100 * acquiring);

  input.innerText = pay;
  fullAmount.value = parseFloat(pay) + parseFloat(amount.value);
}

function IsInternetAcquiringEqualZero() {
  const acquiringField = document.querySelector('.js-sberbank-interest_rate_of_internet_acquiring');
  const amount = document.querySelector('.js-sberbank-amount');
  const acquiring = parseFloat(acquiringField.value);
  const pay = Math.floor(parseFloat(amount.value) / 100 * acquiring);

  if (parseFloat(0) === parseFloat(acquiring)) return true;
  if (parseFloat(0) === parseFloat(pay)) return true;

  return false;
}

function ShowInternetAcquiringFields() {
  const fields = document.querySelector('.js-sberbank-internet_acquiring-fields');

  if (fields) {
    fields.style.display = 'inline-block';
  }
}

function HideInternetAcquiringFields() {
  const fields = document.querySelector('.js-sberbank-internet_acquiring-fields');

  if (fields) {
    fields.style.display = 'none';
  }
}

export { InternetAcquiring, ShowInternetAcquiringFields, HideInternetAcquiringFields, IsInternetAcquiringEqualZero };
