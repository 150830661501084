import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class AdultsSelect extends Component {
  constructor(props) {
    super(props);
  }

  showOrHide() {
    return this.props.maxChildsCount === 0 ? 'hide' : '';
  }

  render() {
    const { maxAdultsCount, minAdultsCount, adultsCount, handleAdultsChange } = this.props;
    const maxAdultsDisabledClass = maxAdultsCount === adultsCount ? ' people-select__add-button--disabled' : '';
    const minAdultsDisabledClass = minAdultsCount === adultsCount ? ' people-select__minus-button--disabled' : '';

    return (
      <div className='people-select__adults'>
        <h6 className={`font-gray ${this.showOrHide()}`}>Взрослые</h6>
        <div className='people-select__category-row'>
          <div className='people-select__minus-button-cell'>
            <i
              className={`people-select__minus-button${minAdultsDisabledClass}`}
              onClick={handleAdultsChange.bind(null, -1)}
            />
          </div>
          <div className='people-select__adults-count-cell'>
            {adultsCount}
          </div>
          <div className='people-select__add-button-cell'>
            <i
              className={`people-select__add-button${maxAdultsDisabledClass}`}
              onClick={handleAdultsChange.bind(null, 1)}
            />
          </div>
        </div>
      </div>
    );
  }
}

AdultsSelect.propTypes = {
  maxAdultsCount: PropTypes.number.isRequired,
  minAdultsCount: PropTypes.number.isRequired,
  adultsCount: PropTypes.number.isRequired,
  handleAdultsChange: PropTypes.func.isRequired
};
