import ClientMemberDataCopy from './ClientMemberDataCopy';
import ClientDataCopyFromOrder from './ClientDataCopyFromOrder';
import { Accommodate, Learned } from '../AccommodateAndLearned';

export default function initDataCopy() {
  const link = document.querySelector('.js-order__copy-client-data-link');
  const link2 = document.querySelector('.js-order__copy-client-data-link-from-order');

  /* HANDLE COPY CLIENT DATA TO MEMBER ON EDIT ORDER */
  if (link) {
    link.removeEventListener('click', ClientMemberDataCopy, false);
    link.addEventListener('click', ClientMemberDataCopy, false);
  }
  /* HANDLE COPY CLIENT DATA FROM OTHER ORDER ON EDIT ORDER */
  if (link2) {
    link2.removeEventListener('click', ClientDataCopyFromOrder, false);
    link2.addEventListener('click', ClientDataCopyFromOrder, false);
  }
  /* HANDLE ACCOMMODATE_WITH_WISH_TYPE CHANGE */
  Accommodate();
  /* HANDLE LEARNED_FROM_TYPE CHANGE */
  Learned();
}
