export default function IsAmountValid(input) {
  const amount = input.value;
  const regExpPattern = /^\d+(\.\d{1,2})?$/;
  // const regExpPattern = /^\d+?$/;
  const validate = regExpPattern.test(amount);

  if (validate && (parseFloat(amount) !== 0)) {
    return true;
  }

  return false;
}
