import ClickBtnSubmit from './ClickBtnSubmit';

export default function InitBtnSubmit() {
  const btn = document.querySelector('.js-sberbank-submit-button');

  if (btn) {
    btn.removeEventListener('click', ClickBtnSubmit, false);
    btn.addEventListener('click', ClickBtnSubmit, false);
  }
}
