import React from 'react';

export default class OfferSubtypesFilter extends React.Component {

  render() {
    let that = this;
    let subTypesNodes = this.props.subTypes.map(function (subType, i) {
      return (
        <OfferSubtype
          key={subType.id}
          subType={subType}
          onSubtypeClick={that.props.onSubtypeClick}
          end={i==that.props.subTypes.length-1}/>
      );
    });

    return (
      <div className='offers-subtype-filter row'>{subTypesNodes}</div>
    );
  }

}
