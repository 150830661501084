import IsAmountValid from './Validates/IsAmountValid';
import { action } from '../../../common/javascript/api';

export default function ClickBtnSubmit(e) {
  e.preventDefault();

  const form = document.querySelector('.js-sberbank-form');
  const inputError = document.querySelector('.js-sberbank-small');

  if (!IsAmountValid(form.full_amount)) return;

  form.button.disabled = true;
  inputError.style.display = 'none';

  const data = {
    order_id: form.order_id.value,
    authenticity_token: form.authenticity_token.value,
    amount: form.full_amount.value,
    cashback_mir: form.cashback.value
  };

  action(form.url.value, data, 'POST')
  .then(
    result => {
      form.button.disabled = false;
      const ret = result.result;
      if (ret.errorCode) {
        inputError.style.display = 'block';
        inputError.innerText = ret.errorMessage;
      } else {
        window.location = ret.formUrl
      }
    },
    error => {
      form.button.disabled = true;
      console.log(error);
    }
  );
}
