export default function ClientDataCopyFromOrder() {
  const client = document.querySelector('.js-order__client-fields');
  const allDataClient = document.querySelectorAll('.js-order__client-fields-from-order');

  allDataClient.forEach((item) => {
    const fieldForCopy = client.querySelector(`#${item.getAttribute('id')}`);
    if (fieldForCopy.value !== '' && fieldForCopy.value !== null && fieldForCopy.value !== undefined) return;
    fieldForCopy.value = item.value;
  });
}
