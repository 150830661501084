import React from 'react';
import numeral from 'numeral';

export default class OfferItem extends React.Component {

  render() {
    let offerStyle = {
      backgroundImage: 'url(' + this.props.offer.cover_image + ')'
    };

    let minCost      = numeral(this.props.offer.min_cost).format("0,0"),
        // priceInclude = this.props.offer.offer_include_text, //this.props.offer.programs_names.join(", ").toLowerCase(),
        offerClasses = ["offer", "row"],
        flagClasses  = ["flag ", this.props.offer.country_code].join(" ");

        if(this.props.even) { offerClasses.push("even") }

    return (
        <div className={offerClasses.join(" ")} style={offerStyle}>
          <a href={this.props.url} className="hide-for-small"><div className="offer-overlay"></div></a>
          <div className="offer-info">
            <div className="spot">
              <ul className="f32 no-bullet">
                <li className={flagClasses}>{this.props.offer.country_name}, {this.props.offer.spot}</li>
              </ul>
            </div>
            <div className="date uppercase font-semi-bold">
              {this.props.offer.offer_showing_period}
              {/*{this.props.offer.offer_showing_period_2 == '' ? '' : <br/>}
              {this.props.offer.offer_showing_period_2 == '' ? '' : this.props.offer.offer_showing_period_2}*/}
            </div>
            <div className="offer-name uppercase">
              <strong>{this.props.offer.name}</strong>
            </div>
            <div className="short-description">{this.props.offer.announce}</div>
            <div className="price-description">
              <span className="uppercase"><strong>от&nbsp;</strong></span><span className="price font-bold">{minCost}</span>
              <div className="price-day-pax uppercase">
                <strong>
                  <span className="font-pink">{this.props.offer.currency}</span>
                  <span>&nbsp;за</span>
                  {/*<span className="font-pink">&nbsp;{this.props.offer.min_cost_days}&nbsp;</span>*/}
                </strong>
                <br />
                <strong>
                  <span>с</span>
                  <span className="font-pink">&nbsp;1-го&nbsp;</span>
                  <span>человека</span>
                </strong>
              </div>
              {/*<div className="price-include">
                В цену вкл.: {priceInclude}
              </div>
            */}
            </div>
            <a className="offer-more button small uppercase" href={this.props.url}>Подробнее&nbsp; <i className="fa fa-angle-double-right fa-lg"></i></a>
          </div>
        </div>
   );
  }

}
