import React from 'react';

export default class SingleChoiceProgram extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      activeProductId: this.props.activeProduct
    };

    this._handleChange = this._handleChange.bind(this);
  }

  _handleChange(e) {
    let newActiveProductId = parseInt(e.target.value);

    if (newActiveProductId !== this.state.activeProductId) {
      this.setState({ activeProductId: newActiveProductId });
      // call parent callback
      this.props.onChange(newActiveProductId);
    }
  }

  // RENDER
  render() {
    let productsNodes = [],
        product = null;

    for (let i = 0, l = this.props.program.products.length; i < l; i++) {
      product = this.props.program.products[i];
      productsNodes.push(
        <div key={product.id}>
          <input type='radio'
            id={`product_${product.id}`}
            value={product.id}
            checked={this.state.activeProductId === product.id}
            name={`program_${this.props.program.id}`}
            onChange={this._handleChange}
            className='fa-font-radio'
          />
          <label htmlFor={`product_${product.id}`}>{product.custom_name}</label>
          {
            product.withDescription &&
            <a href={product.location} target='_blank' className='offer-book-block__product-info-link'>
              <i className='fa fa-info-circle fa-lg'></i>
            </a>
          }
        </div>
      );
    }

    return (
      <div className='offer-book-block__single-choice-program'>
        <h6 className='font-gray'>{this.props.program.name}</h6>
        {productsNodes}
      </div>
    );
  }
}
